import { defineMessages } from "react-intl";

// Käännettävät merkkijonot Job sivulla
export const messages = defineMessages({
	authError: {
		id: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.",
		defaultMessage: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen."
	},
	authErrorShort: {
		id: "Tunnistautuminen epäonnistui",
		defaultMessage: "Tunnistautuminen epäonnistui"
	},
	authRequired: {
		id: "Sinun täytyy tunnistautua ennen kuin voit käyttää MyRopoa.",
		defaultMessage: "Sinun täytyy tunnistautua ennen kuin voit käyttää MyRopoa."
	},
	retrySearch: {
		id: "Emme valitettavasti löytäneet tehtävän tietoja. Ole hyvä ja hae tehtävä uudelleen.",
		defaultMessage: "Emme valitettavasti löytäneet tehtävän tietoja. Ole hyvä ja hae tehtävä uudelleen."
	},
    loading: {
        id: "Ladataan",
        defaultMessage: "Ladataan"
    },
    openingInvoice: {
        id: "Avataan laskua",
        defaultMessage: "Avataan laskua"
    },
	openingCurrentLetter: {
		id: "Avataan ajantasainen maksukirje",
		defaultMessage: "Avataan ajantasainen maksukirje"
	},
	letterDisclaimer: {
		id: "Huomaathan, että nyt ladattavan kirjeen yksittäiset tiedot saattavat poiketa sinulle aiemmin lähetetystä kirjeversiosta.",
		defaultMessage: "Huomaathan, että nyt ladattavan kirjeen yksittäiset tiedot saattavat poiketa sinulle aiemmin lähetetystä kirjeversiosta."
	},
	actionSaving: {
		id: "Tallennetaan tietoja...",
		defaultMessage: "Tallennetaan tietoja..."
	},
	changesSaved: {
		id: 'Muutoksesi on nyt tallennettu',
		defaultMessage: 'Muutoksesi on nyt tallennettu'
	},
	payplanSent: {
		id: "Maksusuunnitelma on nyt tallennettu ja löytyy laskun tietojen yhteydestä. Maksathan erät sovittujen eräpäivien mukaisesti, jotta maksusuunnitelma pysyy voimassa.",
		defaultMessage: "Maksusuunnitelma on nyt tallennettu ja löytyy laskun tietojen yhteydestä. Maksathan erät sovittujen eräpäivien mukaisesti, jotta maksusuunnitelma pysyy voimassa."
	},
	searchingForInvoice: {
		id: "Etsitään laskua...",
		defaultMessage: "Etsitään laskua..."
	},
	payplanTooLong: {
		id: "Maksusuunnitelman kesto on liian pitkä. Ole hyvä ja lyhennä maksusuunnitelman pituutta vähentämällä maksuerien määrää tai väliä.",
		defaultMessage: "Maksusuunnitelman kesto on liian pitkä. Ole hyvä ja lyhennä maksusuunnitelman pituutta vähentämällä maksuerien määrää tai väliä."
	},
	verifyingLink: {
		id: "Varmennetaan linkki...",
		defaultMessage: "Varmennetaan linkki..."
	},
	titleThankYou: {
		id: "Kiitos",
		defaultMessage: "Kiitos"
	},
	accountNumberReceived: {
		id: "Olemme vastaanottaneet ilmoittamasi tilinumeron ja otamme palautuksesi käsittelyyn mahdollisimman pian.",
		defaultMessage: "Olemme vastaanottaneet ilmoittamasi tilinumeron ja otamme palautuksesi käsittelyyn mahdollisimman pian."
	},
	titleSavingFailed: {
		id: 'Tallennus epäonnistui',
		defaultMessage: 'Tallennus epäonnistui'
	},
	titlePdfDownloadFailed: {
		id: 'Lataus epäonnistui',
		defaultMessage: 'Lataus epäonnistui'
	},
	titlePaymentTerminalFailed: {
		id: 'Maksua ei voitu suorittaa.',
		defaultMessage: 'Maksua ei voitu suorittaa.'
	},
	somethingWentWrong: {
		id: "Tapahtui virhe. Etsintä epäonnistui.",
		defaultMessage: "Tapahtui virhe. Etsintä epäonnistui."
	},
	pleaseTryAgain: {
		id: 'Ole hyvä ja yritä uudelleen.',
		defaultMessage: 'Ole hyvä ja yritä uudelleen.'
	},
	changingPaydateNotAllowed: {
		id: 'Eräpäivää ei voida siirtää uudelleen.',
		defaultMessage: 'Eräpäivää ei voida siirtää uudelleen.'
	},
	creatingPayplanNotAllowed: {
		id: 'Maksusuunnitelmaa ei voida luoda.',
		defaultMessage: 'Maksusuunnitelmaa ei voida luoda.'
	},
	movePaydate: {
		id: "Siirrä eräpäivää",
		defaultMessage: "Siirrä eräpäivää"
	},
	paydate: {
		id: "Eräpäivä",
		defaultMessage: "Eräpäivä"
	},
	payplan: {
		id: "Maksusuunnitelma",
		defaultMessage: "Maksusuunnitelma"
	},
    paymentIsDue1: {
        id: "Maksun erä",
        defaultMessage: "Maksun erä"
    },
    paymentIsDue2: {
        id: "erääntyy",
        defaultMessage: "erääntyy"
    },
    showPaymentPlan: {
        id: "Näytä maksusuunnitelma",
        defaultMessage: "Näytä maksusuunnitelma"
    },
    actions: {
        id: "Lisätoiminnot",
        defaultMessage: "Lisätoiminnot"
    },
    showInvoiceDetails: {
        id: "Näytä laskun tiedot",
        defaultMessage: "Näytä laskun tiedot"
    },
    hideInvoiceDetails: {
        id: "Piilota laskun tiedot",
        defaultMessage: "Piilota laskun tiedot"
    },
	mandate: {
		id: "Valtuutus",
		defaultMessage: "Valtuutus"
	},
	overPaidReturn: {
		id: "Tilinumeron ilmoittaminen",
		defaultMessage: "Tilinumeron ilmoittaminen"
	},
	dueNow: {
		id: "Erääntyy tänään",
		defaultMessage: "Erääntyy tänään"
	},
	due: {
		id: "Erääntynyt",
		defaultMessage: "Erääntynyt"
	},
	address: {
		id: "Osoite",
		defaultMessage: "Osoite"
	},
	updateAddress: {
		id: "Päivitä osoitetiedot",
		defaultMessage: "Päivitä osoitetiedot"
	},
    goBack: {
        id: "Takaisin etusivulle",
        defaultMessage: "Takaisin etusivulle"
    },
    statusInformation: {
        id: "Laskun tila",
        defaultMessage: "Laskun tila"
    },
    invoiceOverview: {
        id: "Laskun tiedot",
        defaultMessage: "Laskun tiedot"
    },
    showDetails: {
        id: "Näytä enemmän",
        defaultMessage: "Näytä enemmän"
    },
    hideDetails: {
        id: "Näytä vähemmän",
        defaultMessage: "Näytä vähemmän"
    },
	pay: {
		id: "Maksa",
		defaultMessage: "Maksa"
	},
	payer: {
		id: "Maksaja",
		defaultMessage: "Maksaja"
	},
	creditor: {
		id: "Laskuttaja",
		defaultMessage: "Laskuttaja"
	},
	jobid: {
		id: "Asianumero",
		defaultMessage: "Asianumero"
	},
	billNum: {
		id: "Laskun numero",
		defaultMessage: "Laskun numero"
	},
	billInformation: {
		id: "Laskun tiedot",
		defaultMessage: "Laskun tiedot"
	},
	invoiceRows: {
		id: "Laskuerittely",
		defaultMessage: "Laskuerittely"
	},
	payplanSteps: {
		id: "Maksusuunnitelma",
		defaultMessage: "Maksusuunnitelma"
	},
    paymentDetails: {
        id: "Maksun tiedot",
        defaultMessage: "Maksun tiedot"
    },
	openTotal: {
		id: "Avoinna yhteensä",
		defaultMessage: "Avoinna yhteensä"
	},
	originalCapital: {
		id: "Alkuperäinen pääoma",
		defaultMessage: "Alkuperäinen pääoma"
	},
	openCapital: {
		id: "Avoinna oleva pääoma",
		defaultMessage: "Avoinna oleva pääoma"
	},
	openExpenses: {
		id: "Avoinna olevat kulut",
		defaultMessage: "Avoinna olevat kulut"
	},
	lateInterest: {
		id: "Viivästyskorko",
		defaultMessage: "Viivästyskorko"
	},
	statusLabel: {
		id: "Tila",
		defaultMessage: "Tila"
	},
	jobLoadSuccess: {
		id: "Laskun tiedot ladattu.",
		defaultMessage: "Laskun tiedot ladattu."
	},
	openAmount: {
		id: "Avoinna",
		defaultMessage: "Avoinna"
	},
	sum: {
		id: "Summa",
		defaultMessage: "Summa"
	},
	paid: {
		id: "Maksettu",
		defaultMessage: "Maksettu"
	},
	reference: {
		id: "Maksuviite",
		defaultMessage: "Maksuviite"
	},
	accountInformation: {
		id: "Pankkitilin tiedot",
		defaultMessage: "Pankkitilin tiedot"
	},
    iban: {
        id: "IBAN",
        defaultMessage: "IBAN"
    },
    bic: {
        id: "BIC",
        defaultMessage: "BIC"
	},
    virtualbarcode: {
        id: "Virtuaaliviivakoodi",
        defaultMessage: "Virtuaaliviivakoodi"
    },
	bankgirot: {
		id: "BANKGIROT",
		defaultMessage: "BANKGIROT"
	},
	bban: {
		id: "BBAN",
		defaultMessage: "BBAN"
	},
	paymentInformation: {
		id: "Maksutiedot",
		defaultMessage: "Maksutiedot"
	},
	overpaidReceiver: {
		id: "Ylisuorituksen saaja",
		defaultMessage: "Ylisuorituksen saaja"
	},
    paymentReceiver: {
      id: "Maksun saaja",
      defaultMessage: "Maksun saaja"
    },
	jobMembers: {
		id: "Laskujen tiedot asianumeroittain",
		defaultMessage: "Laskujen tiedot asianumeroittain"
	},
    jobMembersDescription: {
        id: "Täältä löydät alkuperäiset velkaasi liittyvät laskut asianumeroittain.",
        defaultMessage: "Täältä löydät alkuperäiset velkaasi liittyvät laskut asianumeroittain."
    },
    toggleChat: {
	    id: "Avaa Chat",
        defaultMessage: "Avaa Chat"
    },
	overpaidInformation: {
		id: "Maksunpalautus",
		defaultMessage: "Maksunpalautus"
	},
	paymentDate: {
		id: "Maksupäivä",
		defaultMessage: "Maksupäivä"
	},
	overpaidAmount: {
		id: "Palautettava summa",
		defaultMessage: "Palautettava summa"
	},
	overpaidFee: {
		id: "Maksun palautuksen käsittelykulu",
		defaultMessage: "Maksun palautuksen käsittelykulu"
	},
	startingOnlinePayment: {
		id: "Siirrytään verkkopankkiin",
		defaultMessage: "Siirrytään verkkopankkiin"
	},
	invoiceImage: {
		id: "Avaa alkuperäinen lasku",
		defaultMessage: "Avaa alkuperäinen lasku"
	},
	invoiceCurrentImage: {
		id: "Avaa ajantasainen maksukirje",
		defaultMessage: "Avaa ajantasainen maksukirje"
	},
	ibanReceived: {
		id: "Olemme vastaanottaneet ilmoittamasi tilinumeron. Löydät ilmoittamasi tiedot Maksunpalautus -osiosta.",
		defaultMessage: "Olemme vastaanottaneet ilmoittamasi tilinumeron. Löydät ilmoittamasi tiedot Maksunpalautus -osiosta."
	},
	payDateMovedInfoMessage: {
		id: "Eräpäiväksi asetettiin valitsemasi päivämäärän sijaan seuraava arkipäivä, koska valitsemasi eräpäivä ei ollut arkipäivä.",
		defaultMessage: "Eräpäiväksi asetettiin valitsemasi päivämäärän sijaan seuraava arkipäivä, koska valitsemasi eräpäivä ei ollut arkipäivä."
	},
	fireSafetyEnsured: {
		id: "Paloturvallisuus varmistettu MyRopossa.",
		defaultMessage: "Paloturvallisuus varmistettu MyRopossa."
	},
	telephoneNumber: {
		id: "Puhelinnumero",
		defaultMessage: "Puhelinnumero"
	},
    contactInfoWithoutChat: {
        id: "Otathan tarvittaessa yhteyttä asiakaspalveluumme puhelimitse. Oikean puhelinnumeron löydät laskun, maksumuistutuksen tai perintäkirjeen yhteystiedoista.",
        defaultMessage: "Otathan tarvittaessa yhteyttä asiakaspalveluumme puhelimitse. Oikean puhelinnumeron löydät laskun, maksumuistutuksen tai perintäkirjeen yhteystiedoista."
    },
    contactInfoWithChat: {
        id: "Otathan tarvittaessa yhteyttä asiakaspalveluumme chat-painikkeen kautta tai puhelimitse. Oikean puhelinnumeron löydät laskun, maksumuistutuksen tai perintäkirjeen yhteystiedoista.",
        defaultMessage: "Otathan tarvittaessa yhteyttä asiakaspalveluumme chat-painikkeen kautta tai puhelimitse. Oikean puhelinnumeron löydät laskun, maksumuistutuksen tai perintäkirjeen yhteystiedoista."
    },
	cannotBePaidOnline: {
		id: "Laskua ei voi maksaa MyRopon kautta.",
        defaultMessage: "Laskua ei voi maksaa MyRopon kautta."
    },
	errorOccurred: {
		id: "Tapahtui virhe",
        defaultMessage: "Tapahtui virhe",
    },
	somethingWentWrongTryAgain: {
		id: "Jotain meni vikaan, ole hyvä ja yritä uudelleen.",
        defaultMessage: "Jotain meni vikaan, ole hyvä ja yritä uudelleen.",
    },
	signServing: {
		id: "Allekirjoita tiedoksianto",
        defaultMessage: "Allekirjoita tiedoksianto",
    },
    getSupport: {
        id: "Tarvitsetko apua?",
        defaultMessage: "Tarvitsetko apua?",
    },
	messageCenterMessages: {
        id: "Viestikeskus",
        defaultMessage: "Viestikeskus",
    },
	createNewMessage: {
		id: "Aloita uusi viestiketju",
		defaultMessage: "Aloita uusi viestiketju",
	},
	servingPanelHeader: {
		id: "Tiedoksianto",
        defaultMessage: "Tiedoksianto",
    },
	documentToSign: {
		id: "Sinulla on allekirjoitettava asiakirja",
        defaultMessage: "Sinulla on allekirjoitettava asiakirja",
    },
	servingInformation: {
		id: "Tiedoksiantoon vaaditaan allekirjoituksesi. Tiedoksiannon ja 1 500 kruunun lisäkulujen välttämiseksi sinua pyydetään lukemaan ja allekirjoittamaan tämä asiakirja viimeistään tilan eräpäivään mennessä. Jos näin ei tapahdu määräaikaan mennessä, asiakirja luovutetaan ilman erillistä ilmoitusta tiedonksiantohenkilölle toimitettavaksi.",
        defaultMessage: "Tiedoksiantoon vaaditaan allekirjoituksesi. Tiedoksiannon ja 1 500 kruunun lisäkulujen välttämiseksi sinua pyydetään lukemaan ja allekirjoittamaan tämä asiakirja viimeistään tilan eräpäivään mennessä. Jos näin ei tapahdu määräaikaan mennessä, asiakirja luovutetaan ilman erillistä ilmoitusta tiedonksiantohenkilölle toimitettavaksi.",
    },
	servingInformationHeating: {
		id: "Sinut etsitään asiakirjan toimittamisesta. Tiedoksiannon ja 1 500 kruunun lisäkulujen välttämiseksi sinua pyydetään lukemaan ja allekirjoittamaan tämä asiakirja viimeistään tilan eräpäivään mennessä. Jos näin ei tapahdu kymmenen (10) päivän kuluessa lähetetyn kirjeen päiväyksestä, asiakirja luovutetaan ilman erillistä ilmoitusta tiedonksiantohenkilölle toimitettavaksi.",
        defaultMessage: "Sinut etsitään asiakirjan toimittamisesta. Tiedoksiannon ja 1 500 kruunun lisäkulujen välttämiseksi sinua pyydetään lukemaan ja allekirjoittamaan tämä asiakirja viimeistään tilan eräpäivään mennessä. Jos näin ei tapahdu kymmenen (10) päivän kuluessa lähetetyn kirjeen päiväyksestä, asiakirja luovutetaan ilman erillistä ilmoitusta tiedonksiantohenkilölle toimitettavaksi.",
	},
	signatureReceived: {
		id: "Olemme vastaanottaneet allekirjoituksesi",
        defaultMessage: "Olemme vastaanottaneet allekirjoituksesi",
    },
	signatureDate: {
		id: "Päivämäärä",
        defaultMessage: "Päivämäärä",
    },
	moreInformation: {
		id: "Lisätietoja",
        defaultMessage: "Lisätietoja",
    },
	strongAuthenticationNeeded: {
		id: "Tiedoksiannon allekirjoittaminen vaatii vahvan tunnistautumisen. Ole hyvä ja kirjaudu sisään allekirjoittaaksesi tiedoksiannon.",
        defaultMessage: "Tiedoksiannon allekirjoittaminen vaatii vahvan tunnistautumisen. Ole hyvä ja kirjaudu sisään allekirjoittaaksesi tiedoksiannon.",
    },
    pdfCantBeLoaded: {
        id: "Alkuperäistä laskun kuvaa ei ole Ropolta saatavilla. Ole hyvä ja pyydä lasku suoraan laskuttajalta.",
        defaultMessage: "Alkuperäistä laskun kuvaa ei ole Ropolta saatavilla. Ole hyvä ja pyydä lasku suoraan laskuttajalta."
    },
	moveToPreviousJob: {
		id: "Edellinen lasku",
        defaultMessage: "Edellinen lasku"
	},
	moveToNextJob: {
		id: "Seuraava lasku",
        defaultMessage: "Seuraava lasku"
	},
    underMaintenance: {
        id: "Pahoittelemme, viestikeskus on tilapäisesti poissa käytöstä huollon vuoksi. Yritäthän myöhemmin uudelleen.",
        defaultMessage: "Pahoittelemme, viestikeskus on tilapäisesti poissa käytöstä huollon vuoksi. Yritäthän myöhemmin uudelleen."
    },
	ongoingMessaging: {
        id: "Sinulla on aktiivinen viestiketju asiakaspalvelun kanssa. Ole hyvä ja siirry keskusteluun allaolevasta listasta.",
        defaultMessage: "Sinulla on aktiivinen viestiketju asiakaspalvelun kanssa. Ole hyvä ja siirry keskusteluun allaolevasta listasta.",
    },
	messageIsSent: {
		id: "Kiitos viestistäsi!",
		defaultMessage: "Kiitos viestistäsi!"
	},
	loginToSendMessage: {
		id: "Lähettääksesi viestin, ole hyvä ja kirjaudu sisään.",
		defaultMessage: "Lähettääksesi viestin, ole hyvä ja kirjaudu sisään."
	}
});

// Tehtävän tilakoodin konversiot
export const debtorStatusMessages = defineMessages({
	statuscode0: {
		id: "Laskutus",
		defaultMessage: "Laskutus"
	},
	statuscode1: {
		id: "Muistutus",
		defaultMessage: "Muistutus"
	},
	statuscode2: {
		id: "Perintä",
		defaultMessage: "Perintä"
	},
	statuscode6: {
		id: "Velkajärjestely",
		defaultMessage: "Velkajärjestely"
	},
	statuscode11: {
		id: "Tratta",
		defaultMessage: "Tratta"
	},
	statuscode13: {
		id: "Protestoidaan",
		defaultMessage: "Protestoidaan"
	},
	statuscode14: {
		id: "Protestoitu",
		defaultMessage: "Protestoitu"
	},
	statuscode15: {
		id: "Konkurssiuhkainen maksukehotus",
		defaultMessage: "Konkurssiuhkainen maksukehotus"
	},
	statuscode16: {
		id: "Oikeudellisten perintätoimien harkinta",
		defaultMessage: "Oikeudellisten perintätoimien harkinta"
	},
	statuscode17: {
		id: "Jälkiperintä",
		defaultMessage: "Jälkiperintä"
	},
	statuscode31: {
		id: "Maksusuunnitelma",
		defaultMessage: "Maksusuunnitelma"
	},
	statuscode55: {
		id: "Kansainvälisten perintätoimien harkinta",
		defaultMessage: "Kansainvälisten perintätoimien harkinta"
	},
	statuscode63: {
		id: "Haastehakemus lähetetty käräjäoikeuteen",
		defaultMessage: "Haastehakemus lähetetty käräjäoikeuteen"
	},
	statuscode65: {
		id: "Odottaa siirtoa ulosottoon",
		defaultMessage: "Odottaa siirtoa ulosottoon"
	},
	statuscode68: {
		id: "Odottaa - perukirja",
		defaultMessage: "Odottaa - perukirja"
	},
	statuscode71: {
		id: "Virhe - osoitetiedot",
		defaultMessage: "Virhe - osoitetiedot"
	},
	statuscode80: {
		id: "Vapaaehtoisen perinnän toimet lopetettu",
		defaultMessage: "Vapaaehtoisen perinnän toimet lopetettu"
	},
	statuscode81: {
		id: "Selvityksessä",
		defaultMessage: "Selvityksessä"
	},
	statuscode89: {
		id: "Osoitetietojen tarkistus",
		defaultMessage: "Osoitetietojen tarkistus"
	},
	statuscode98: {
		id: "Viesti",
		defaultMessage: "Viesti"
	},
	statuscode99: {
		id: "Jatkettu",
		defaultMessage: "Jatkettu"
	},
	statuscode101: {
		id: "Maksettu",
		defaultMessage: "Maksettu"
	},
	statuscode102: {
		id: "Tehtävä lopetettu",
		defaultMessage: "Tehtävä lopetettu"
	},
	statuscode104: {
		id: "Hyvityslasku",
		defaultMessage: "Hyvityslasku"
	},
	statuscode105: {
		id: "Käteiskuitti",
		defaultMessage: "Käteiskuitti"
	},
	statuscode106: {
		id: "Lähete",
		defaultMessage: "Lähete"
	},
	statuscode110: {
		id: "Saneerausmenettelyssä",
		defaultMessage: "Saneerausmenettelyssä"
	},
	statuscode111: {
		id: "Konkurssimenettelyssä",
		defaultMessage: "Konkurssimenettelyssä"
	},
	statuscode112: {
		id: "Ulosotossa",
		defaultMessage: "Ulosotossa"
	},
	statuscode139: {
		id: "Vanhentunut",
		defaultMessage: "Vanhentunut"
	},
	statuscode150: {
		id: "Katkaisuvaroitus",
		defaultMessage: "Katkaisuvaroitus"
	},
	statuscode151: {
		id: "Katkaisuvaroitus erääntynyt",
		defaultMessage: "Katkaisuvaroitus erääntynyt"
	},
	statuscode153: {
		id: "Katkaisuvaroitus erääntynyt",
		defaultMessage: "Katkaisuvaroitus erääntynyt"
	},
	statuscode154: {
		id: "Katkaisuvaroitus erääntynyt",
		defaultMessage: "Katkaisuvaroitus erääntynyt"
	},
	statuscode155: {
		id: "Katkaisuvaroitus erääntynyt",
		defaultMessage: "Katkaisuvaroitus erääntynyt"
	},
	statuscode156: {
		id: "Katkaisuvaroitus erääntynyt",
		defaultMessage: "Katkaisuvaroitus erääntynyt"
	},
	statuscode158: {
		id: "Kytkentäpyyntö lähetetty",
		defaultMessage: "Kytkentäpyyntö lähetetty"
	},
	statuscode204: {
		id: "Erääntymisilmoitus",
		defaultMessage: "Erääntymisilmoitus"
	},
	statuscode301: {
		id: "Dokumentti",
		defaultMessage: "Dokumentti"
	},
	statuscode302: {
		id: "Kirje",
		defaultMessage: "Kirje"
	},
	statuscode500: {
		id: "Special Handling",
		defaultMessage: "Special Handling"
	},
	statuscode601: {
		id: "Koontilasku",
		defaultMessage: "Koontilasku"
	},
	statuscode700: {
		id: "Siirtoilmoitus",
		defaultMessage: "Siirtoilmoitus"
	}
});

// Eri tehtävän tiloihin liittyvät ohjetekstit
export const debtorStatusDescs = defineMessages({
	statusdesc6: {
		id: "Maksathan laskun velkajärjestelyn maksuohjelman mukaisesti. Löydät maksuerät ja summat omasta vahvistetusta maksuohjelmastasi. Tili- ja viitenumeron voit tarkistaa laskun tiedoista MyRoposta.",
		defaultMessage: "Maksathan laskun velkajärjestelyn maksuohjelman mukaisesti. Löydät maksuerät ja summat omasta vahvistetusta maksuohjelmastasi. Tili- ja viitenumeron voit tarkistaa laskun tiedoista MyRoposta."
	},
	statusdesc11: {
		id: "Kun maksat avoimen summan kokonaisuudessaan eräpäivään mennessä, vältyt tratan mahdolliselta protestoinnilta sekä maksuhäiriömerkinnältä.",
		defaultMessage: "Kun maksat avoimen summan kokonaisuudessaan eräpäivään mennessä, vältyt tratan mahdolliselta protestoinnilta sekä maksuhäiriömerkinnältä."
	},
	statusdesc12: {
		id: "Tratta on erääntynyt ja se voidaan protestoida. Maksathan avoimen summan kokonaisuudessaan viipymättä.",
		defaultMessage: "Tratta on erääntynyt ja se voidaan protestoida. Maksathan avoimen summan kokonaisuudessaan viipymättä."
	},
	statusdesc14: {
		id: "Tratta on protestoitu. Kun maksat avoimen summan kokonaisuudessaan, ilmoitamme rekisterinpitäjälle protestoidun saatavan maksetuksi.",
		defaultMessage: "Tratta on protestoitu. Kun maksat avoimen summan kokonaisuudessaan, ilmoitamme rekisterinpitäjälle protestoidun saatavan maksetuksi."
	},
	statusdesc15: {
		id: "Kun maksat avoimen summan kokonaisuudessaan eräpäivään mennessä, vältät konkurssiuhan.",
		defaultMessage: "Kun maksat avoimen summan kokonaisuudessaan eräpäivään mennessä, vältät konkurssiuhan."
	},
	statusdesc16: {
		id: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta.",
		defaultMessage: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta."
	},
	statusdesc19: {
		id: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta.",
		defaultMessage: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta."
	},
	statusdesc31: {
		id: "Noudatathan maksusuunnitelmalle sovittuja eräpäiviä ja maksueriä tarkasti, jotta maksusuunnitelma pysyy voimassa. Jos maksusuunnitelmaa ei noudateta ja se katkeaa, jäljellä oleva summa erääntyy kerralla maksettavaksi ja laskulle voi muodostua lisäkuluja.",
		defaultMessage: "Noudatathan maksusuunnitelmalle sovittuja eräpäiviä ja maksueriä tarkasti, jotta maksusuunnitelma pysyy voimassa. Jos maksusuunnitelmaa ei noudateta ja se katkeaa, jäljellä oleva summa erääntyy kerralla maksettavaksi ja laskulle voi muodostua lisäkuluja."
	},
	statusdesc41: {
		id: "Noudatathan maksusuunnitelmalle sovittuja eräpäiviä ja maksueriä tarkasti, jotta maksusuunnitelma pysyy voimassa. Jos maksusuunnitelmaa ei noudateta ja se katkeaa, jäljellä oleva summa erääntyy kerralla maksettavaksi ja laskulle voi muodostua lisäkuluja.",
		defaultMessage: "Noudatathan maksusuunnitelmalle sovittuja eräpäiviä ja maksueriä tarkasti, jotta maksusuunnitelma pysyy voimassa. Jos maksusuunnitelmaa ei noudateta ja se katkeaa, jäljellä oleva summa erääntyy kerralla maksettavaksi ja laskulle voi muodostua lisäkuluja."
	},
	statusdesc55: {
		id: "Lasku on siirtymässä kansainväliseen perintään. Kun maksat laskun eräpäivään mennessä vältyt kansainvälisen perinnän lisäkuluilta.",
		defaultMessage: "Lasku on siirtymässä kansainväliseen perintään. Kun maksat laskun eräpäivään mennessä vältyt kansainvälisen perinnän lisäkuluilta."
	},
	statusdesc58: {
		id: "Vapaaehtoisen perinnän toimet on keskeytetty selvityksen ajaksi.",
		defaultMessage: "Vapaaehtoisen perinnän toimet on keskeytetty selvityksen ajaksi."
	},
	statusdesc61: {
		id: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta.",
		defaultMessage: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta."
	},
	statusdesc62: {
		id: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta.",
		defaultMessage: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta."
	},
	statusdesc65: {
		id: "Kun maksat avoimen summan kokonaisuudessaan ennen laskun siirtymistä ulosottoon, vältyt lisäkuluilta.",
		defaultMessage: "Kun maksat avoimen summan kokonaisuudessaan ennen laskun siirtymistä ulosottoon, vältyt lisäkuluilta."
	},
	statusdesc67: {
		id: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta.",
		defaultMessage: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta."
	},
	statusdesc71: {
		id: "Ilmoitathan voimassa olevan osoitteesi asiakaspalveluumme.",
		defaultMessage: "Ilmoitathan voimassa olevan osoitteesi asiakaspalveluumme."
	},
	statusdesc80: {
		id: "Saatavan vapaaehtoinen perintä on lopetettu maksuvelvollisuuden kiistämisen vuoksi eikä saatava ole enää Ropon käsittelyssä. ",
		defaultMessage: "Saatavan vapaaehtoinen perintä on lopetettu maksuvelvollisuuden kiistämisen vuoksi eikä saatava ole enää Ropon käsittelyssä. "
	},
	statusdesc81: {
		id: "Vapaaehtoisen perinnän toimet on keskeytetty selvityksen ajaksi.",
		defaultMessage: "Vapaaehtoisen perinnän toimet on keskeytetty selvityksen ajaksi."
	},
	statusdesc82: {
		id: "Vapaaehtoisen perinnän toimet on keskeytetty selvityksen ajaksi.",
		defaultMessage: "Vapaaehtoisen perinnän toimet on keskeytetty selvityksen ajaksi."
	},
	statusdesc85: {
		id: "Laskun loppusummaan voidaan lisätä mahdolliset oikeudenkäyntikulut, joten MyRopossa näkyvä avoin saldo voi poiketa avoimesta summasta.",
		defaultMessage: "Laskun loppusummaan voidaan lisätä mahdolliset oikeudenkäyntikulut, joten MyRopossa näkyvä avoin saldo voi poiketa avoimesta summasta."
	},
	statusdesc86: {
		id: "Laskun käsittely on keskeytetty ja odotamme toimeksiantajalta lisätietoja.",
		defaultMessage: "Laskun käsittely on keskeytetty ja odotamme toimeksiantajalta lisätietoja."
	},
	statusdesc87: {
		id: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta.",
		defaultMessage: "Kun maksat avoimen summan kokonaisuudessaan ennen oikeudellisten perintätoimien aloittamista, vältyt lisäkuluilta."
	},
	statusdesc88: {
		id: "Lasku on selvityksessä ja käsittely on toistaiseksi keskeytetty.",
		defaultMessage: "Lasku on selvityksessä ja käsittely on toistaiseksi keskeytetty."
	},
	statusdesc89: {
		id: "Ilmoitathan voimassa olevan osoitteesi asiakaspalveluumme.",
		defaultMessage: "Ilmoitathan voimassa olevan osoitteesi asiakaspalveluumme."
	},
	statusdesc110: {
		id: "Maksathan laskun saneerausohjelman mukaisesti. Löydät maksuerät ja summat omasta vahvistetusta maksuohjelmastasi. Tili- ja viitenumeron voit tarkistaa laskun tiedoista MyRoposta.",
		defaultMessage: "Maksathan laskun saneerausohjelman mukaisesti. Löydät maksuerät ja summat omasta vahvistetusta maksuohjelmastasi. Tili- ja viitenumeron voit tarkistaa laskun tiedoista MyRoposta."
	},
	statusdesc112: {
		id: "Laskun käsittely on siirtynyt ulosottoon. Ulosotossa saatavalle on voinut muodostua ulosoton kuluja, jotka eivät näy MyRopossa. Olethan tarvittaessa yhteydessä kotikuntasi ulosottovirastoon.",
		defaultMessage: "Laskun käsittely on siirtynyt ulosottoon. Ulosotossa saatavalle on voinut muodostua ulosoton kuluja, jotka eivät näy MyRopossa. Olethan tarvittaessa yhteydessä kotikuntasi ulosottovirastoon."
	},
	statusdesc140: {
		id: "Perintäkulut perustuvat saatavan vapaaehtoisessa perinnässä tehtyihin toimenpiteisiin. Maksathan avoimen saatavan viipymättä, jotta vapaaehtoinen perintä päättyy.",
		defaultMessage: "Perintäkulut perustuvat saatavan vapaaehtoisessa perinnässä tehtyihin toimenpiteisiin. Maksathan avoimen saatavan viipymättä, jotta vapaaehtoinen perintä päättyy."
	},
	statusdesc141: {
		id: "Perintäkulut perustuvat saatavan vapaaehtoisessa perinnässä tehtyihin toimenpiteisiin. Jos velkaa ei makseta eräpäivään mennessä, joudumme jatkamaan velan perintää, mistä voi aiheutua sinulle lisäkuluja.",
		defaultMessage: "Perintäkulut perustuvat saatavan vapaaehtoisessa perinnässä tehtyihin toimenpiteisiin. Jos velkaa ei makseta eräpäivään mennessä, joudumme jatkamaan velan perintää, mistä voi aiheutua sinulle lisäkuluja."
	},
	statusdesc150: {
		id: "Maksamalla avoimen katkaisuvaroituksen summan viimeistään eräpäivänä, varmistat, ettei jakelusi keskeydy tämän saatavan vuoksi.",
		defaultMessage: "Maksamalla avoimen katkaisuvaroituksen summan viimeistään eräpäivänä, varmistat, ettei jakelusi keskeydy tämän saatavan vuoksi."
	},
	statusdesc151: {
		id: "Kun maksat avoimen summan MyRopossa, ilmoitamme maksustasi automaattisesti energiayhtiöösi ja pyydämme katkaisun peruutusta. Jos energiayhtiösi on kuitenkin ehtinyt jo aloittaa katkaisuun johtavat toimet ja jakelusi keskeytyy, tulee kaikki avoimet erääntyneet laskut, mahdollinen vakuuslasku sekä katkaisu- ja kytkentälasku olla maksettuna ennen takaisinkytkentää. Huomioithan, että Ropo ei tee energianjakelun keskeytyksiä, joten yhteydenottosi asiakaspalveluumme ei vaikuta mahdollisiin katkaisutoimiin.",
		defaultMessage: "Kun maksat avoimen summan MyRopossa, ilmoitamme maksustasi automaattisesti energiayhtiöösi ja pyydämme katkaisun peruutusta. Jos energiayhtiösi on kuitenkin ehtinyt jo aloittaa katkaisuun johtavat toimet ja jakelusi keskeytyy, tulee kaikki avoimet erääntyneet laskut, mahdollinen vakuuslasku sekä katkaisu- ja kytkentälasku olla maksettuna ennen takaisinkytkentää. Huomioithan, että Ropo ei tee energianjakelun keskeytyksiä, joten yhteydenottosi asiakaspalveluumme ei vaikuta mahdollisiin katkaisutoimiin."
	},
	statusdesc153: {
		id: "Katkaisuvaroitus on erääntynyt ja avoimesta laskusta toimitetaan seuraavaksi katkaisupyyntö energiayhtiöllesi. Kun maksat avoimen summan kokonaisuudessaan, voit vielä välttyä katkaisutoimenpiteiltä. MyRopossa maksettuna maksu näkyy järjestelmässämme reaaliaikaisesti.",
		defaultMessage: "Katkaisuvaroitus on erääntynyt ja avoimesta laskusta toimitetaan seuraavaksi katkaisupyyntö energiayhtiöllesi. Kun maksat avoimen summan kokonaisuudessaan, voit vielä välttyä katkaisutoimenpiteiltä. MyRopossa maksettuna maksu näkyy järjestelmässämme reaaliaikaisesti."
	},
	statusdesc154: {
		id: "Lasku on erääntynyt ja käytössäsi olevat palvelut ovat vaarassa keskeytyä. Jos katkaisutoimenpiteet on jo ehditty aloittaa, palvelut avataan vasta, kun kaikki erääntyneet velat, katkaisu- ja kytkentämaksut sekä mahdollisesti vaadittava ennakko- tai vakuusmaksu on maksettu.",
		defaultMessage: "Lasku on erääntynyt ja käytössäsi olevat palvelut ovat vaarassa keskeytyä. Jos katkaisutoimenpiteet on jo ehditty aloittaa, palvelut avataan vasta, kun kaikki erääntyneet velat, katkaisu- ja kytkentämaksut sekä mahdollisesti vaadittava ennakko- tai vakuusmaksu on maksettu."
	},
	statusdesc155: {
		id: "Kun maksat avoimen summan MyRopossa, ilmoitamme maksustasi automaattisesti energiayhtiöösi ja pyydämme katkaisun peruutusta. Jos energiayhtiösi on kuitenkin ehtinyt jo aloittaa katkaisuun johtavat toimet ja jakelusi keskeytyy, tulee kaikki avoimet erääntyneet laskut, mahdollinen vakuuslasku sekä katkaisu- ja kytkentälasku olla maksettuna ennen takaisinkytkentää. Huomioithan, että Ropo ei tee energianjakelun keskeytyksiä, joten yhteydenottosi asiakaspalveluumme ei vaikuta mahdollisiin katkaisutoimiin.",
		defaultMessage: "Kun maksat avoimen summan MyRopossa, ilmoitamme maksustasi automaattisesti energiayhtiöösi ja pyydämme katkaisun peruutusta. Jos energiayhtiösi on kuitenkin ehtinyt jo aloittaa katkaisuun johtavat toimet ja jakelusi keskeytyy, tulee kaikki avoimet erääntyneet laskut, mahdollinen vakuuslasku sekä katkaisu- ja kytkentälasku olla maksettuna ennen takaisinkytkentää. Huomioithan, että Ropo ei tee energianjakelun keskeytyksiä, joten yhteydenottosi asiakaspalveluumme ei vaikuta mahdollisiin katkaisutoimiin."
	},
	statusdesc156: {
		id: "Lasku on erääntynyt ja energiantoimituksen katkaisutoimenpiteet ovat käynnistyneet tai jakelu on jo keskeytetty. Maksettuasi käyttöpaikan puuttuvat maksut pyydämme takaisinkytkentää energiayhtiöltäsi. MyRopossa maksettuna maksu näkyy järjestelmässämme reaaliaikaisesti.",
		defaultMessage: "Lasku on erääntynyt ja energiantoimituksen katkaisutoimenpiteet ovat käynnistyneet tai jakelu on jo keskeytetty. Maksettuasi käyttöpaikan puuttuvat maksut pyydämme takaisinkytkentää energiayhtiöltäsi. MyRopossa maksettuna maksu näkyy järjestelmässämme reaaliaikaisesti."
	},
	statusdesc158: {
		id: "Energiayhtiösi käsittelee nyt takaisinkytkentää ja tekee kytkennän omalla aikataulullaan.",
		defaultMessage: "Energiayhtiösi käsittelee nyt takaisinkytkentää ja tekee kytkennän omalla aikataulullaan."
	},
	statusdesc207: {
		id: "Alkuperäinen velkoja: velkojan nimi",
		defaultMessage: "Alkuperäinen velkoja: velkojan nimi"
	},
	statusdesc700: {
		id: "Alkuperäinen velkoja: velkojan nimi",
		defaultMessage: "Alkuperäinen velkoja: velkojan nimi"
	},
	statusdesc701: {
		id: "Alkuperäinen velkoja: velkojan nimi",
		defaultMessage: "Alkuperäinen velkoja: velkojan nimi"
	},
});

export const prohibited = defineMessages({
	paymentTermExtensionCannotBeMade: {
		id: 'Maksuajan lisääminen on tälle laskulle estetty. Maksuaikaa on saatettu lisätä jo aiemmin.',
		defaultMessage: 'Maksuajan lisääminen on tälle laskulle estetty. Maksuaikaa on saatettu lisätä jo aiemmin.'
	},
	paymentplanCannotBeCreated: {
		id: 'Maksusuunnitelman lisääminen on tälle laskulle estetty. Maksusuunnitelma on mahdollisesti tehty jo aiemmin.',
		defaultMessage: 'Maksusuunnitelman lisääminen on tälle laskulle estetty. Maksusuunnitelma on mahdollisesti tehty jo aiemmin.'
	},
	paymentLimit: {
		id: 'Maksettaessa laskua MyRopon kautta, yksittäisen laskun enimmäissumma voi olla maksimissaan',
		defaultMessage: 'Maksettaessa laskua MyRopon kautta, yksittäisen laskun enimmäissumma voi olla maksimissaan'
	},
	paymentLimitExceeded: {
		id: 'Ole hyvä ja maksa tämän summan ylittävät laskut omassa verkkopankissasi.',
		defaultMessage: 'Ole hyvä ja maksa tämän summan ylittävät laskut omassa verkkopankissasi.'
	},
});

export const mainStatusText = defineMessages({
    invoice: {
        id: 'Lasku',
        defaultMessage: 'Lasku'
    },
    late: {
        id: 'Myöhässä',
        defaultMessage: 'Myöhässä'
    },
    reminder: {
        id: 'Muistutus',
        defaultMessage: 'Muistutus',
    },
    collection: {
        id: 'Perintä',
        defaultMessage: 'Perintä'
    },
    paid: {
        id: 'Maksettu',
        defaultMessage: 'Maksettu'
    }
});