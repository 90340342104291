import React, { useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { Button, Modal, FormGroup, FormSelect, FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { defineMessages } from "react-intl";

import { getAllowedLangs } from "../../../../services/helpers";

import enGB from 'date-fns/locale/en-GB/index';
import fi from 'date-fns/locale/fi'
import sv from 'date-fns/locale/sv';
// date-fns supports Norwegian Bokmål for datepicker
import nb from 'date-fns/locale/nb';
import Moment from "moment";
import AlertDialog from "../../../../components/AlertDialog";
import PropTypes from "prop-types";

registerLocale('fi', fi);
registerLocale('en', enGB);
registerLocale('se', sv);
registerLocale('no', nb);

// This view's strings for translation
const messages = defineMessages({
    makePayplan: {
        id: "Tee maksusuunnitelma",
        defaultMessage: "Tee maksusuunnitelma"
    },
    paragraph1: {
        id: "Alla olevalla lomakkeella voit tehdä maksusuunnitelman laskullesi. Maksusuunnitelmasta veloitetaan perintälaissa määritelty kulu. Erien väli on 4 viikkoa.",
        defaultMessage: "Alla olevalla lomakkeella voit tehdä maksusuunnitelman laskullesi. Maksusuunnitelmasta veloitetaan perintälaissa määritelty kulu. Erien väli on 4 viikkoa."
    },
    paragraph2: {
        id: "Jos sinulla on kysyttävää maksusuunnitelman tekemisestä ole yhteydessä",
        defaultMessage: "Jos sinulla on kysyttävää maksusuunnitelman tekemisestä ole yhteydessä"
    },
    paragraph3: {
        id: "Maksusuunnitelmasta veloitettava kulu",
        defaultMessage: "Maksusuunnitelmasta veloitettava kulu"
    },
    paragraph4: {
        id: "Palvelumaksun lisäksi maksusuunnitelman kokonaiskustannuksiin voi vaikuttaa myös laskulle mahdollisesti kertynyt viivästyskorko.",
        defaultMessage: "Palvelumaksun lisäksi maksusuunnitelman kokonaiskustannuksiin voi vaikuttaa myös laskulle mahdollisesti kertynyt viivästyskorko.",
    },
    ropoCustomerService: {
        id: "Ropon asiakaspalveluun",
        defaultMessage: "Ropon asiakaspalveluun"
    },
    actionSave: {
        id: "Tallenna",
        defaultMessage: "Tallenna"
    },
    chooseFirstPaydate: {
        id: "Valitse ensimmäinen eräpäivä",
        defaultMessage: "Valitse ensimmäinen eräpäivä"
    },
    choosePayments: {
        id: "Valitse maksuerien määrä",
        defaultMessage: "Valitse maksuerien määrä"
    },
    backToInvoice: {
        id: "Palaa laskun tietoihin",
        defaultMessage: "Palaa laskun tietoihin"
    }
});

export { messages };

const PayplanModal = (props) => {
    
    // function for translations
    let lng = props.intl.formatMessage;
    // get allowed languages from helpers
    const allowedLangs = getAllowedLangs();
    const operativeCountry = localStorage.getItem('country');
    const locale = localStorage.getItem('locale');
    // set default locale for datepicker instances
    setDefaultLocale(locale !== null && allowedLangs.includes(locale) ? locale : 'fi');
    let paydate = Moment(props.paydate).format('YYYY-MM-DD');
    
    // if job's original paydate has already passed, set current date as the first possible paydate for payment plan
    if (Moment(props.paydate).format('x') < Moment().format('x')) {
        paydate = Moment().format('YYYY-MM-DD');
    }
    
    const [firstpaydate, setFirstpaydate] = useState(Moment(paydate).toDate());
    // datepicker time range is 0-20 days from the job's original paydate
    let startdate = Moment(paydate).toDate();
    let enddate = Moment(paydate).add(20, 'days').toDate();
    // default number of installments is two
    let payplanOptions = [2];
    // add more options for installments if available

    if (props.payplanmaxlength >= 2) {
        for (let i = 2; i <= props.payplanmaxlength; i++) {
            payplanOptions.push(i + 1);
        }
    }
    
    // handles the changes in the datepicker and passes updates to the parent
    function handleDatePicker(date) {
        setFirstpaydate(date);
        props.handleDatepickerChange(date);
        props.showAlertDialog.show = false;
    }
  
    return (
        <Modal show={ props.show } onHide={ props.hide } aria-labelledby="modal-title-payplan">
            <Modal.Header closeButton>
                <Button className="modal-back" onClick={ props.hide }>
                    <FontAwesomeIcon icon={ far.faArrowLeft } /> { lng(messages.backToInvoice) }
                </Button>
                <Modal.Title id="modal-title-payplan">{ lng(messages.makePayplan) }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p> { lng(messages.paragraph1) }</p>
                <p>{ lng(messages.paragraph2) } <a title={ lng(messages.ropoCustomerService) } rel="noopener noreferrer" href="https://www.ropocapital.fi/fi/yhteystiedot/" target="_blank">{ lng(messages.ropoCustomerService) }</a>.</p>
                <p>{ lng(messages.paragraph4) }</p>
                { props.payplanfee !== '' ? (
                    <p className="fw-bold text-danger">{ lng(messages.paragraph3) }: { props.payplanfee }</p>
                ) : ('') }
                { props.showAlertDialog.show && (
                    <AlertDialog message={ props.showAlertDialog.message } type={ props.showAlertDialog.type } />
                ) }
                <form onSubmit={ props.handleSubmit }>
                    <FormGroup controlId="firstpaydate" className="form-group has-label text-center mb-3">
                        <FormLabel>{ lng(messages.chooseFirstPaydate) }</FormLabel>
                        <DatePicker id="firstpaydate" minDate={ startdate } maxDate={ enddate } selected={ firstpaydate } onChange={ (e) => handleDatePicker(e) } locale={ operativeCountry } className="form-control"
                                    inline />
                    </FormGroup>
                    <FormGroup controlId="paymentcount" className="form-floating">
                        <FormSelect role="select" className="form-control mb-3" onChange={ props.updateState } value={ props.paymentcount }>
                            { payplanOptions.map((option) => {
                                return (
                                    <option key={ option } value={ option }> { option }</option>
                                )
                            }) }
                        </FormSelect>
                        <label>{ lng(messages.choosePayments) }</label>
                        <Button variant="primary" type="submit">
                            { lng(messages.actionSave) }
                        </Button>
                    </FormGroup>
                </form>
            </Modal.Body>
        </Modal>
    )
};

PayplanModal.propTypes = {
    intl: PropTypes.object.isRequired,
    paydate: PropTypes.string,
    payplanmaxlength: PropTypes.any,
    handleDatepickerChange: PropTypes.func,
    showAlertDialog: PropTypes.object,
    show: PropTypes.bool,
    hide: PropTypes.func,
    payplanfee: PropTypes.any,
    handleSubmit: PropTypes.func,
    updateState: PropTypes.func,
    paymentcount: PropTypes.any,
};

export default (PayplanModal);